function ga_track(cat, action, label, elm) {
	if(cat.length > 0) {
		ga('send', {
		  hitType: 'click',
		  eventCategory: cat,
		  eventAction: action,
		  eventLabel: label
		}); 
	}
}

function adwords(id, label) {
	console.log('Awrods ---- tracking ---- ID:' + id + ' Label:' + label);
	var goalId = 870767140;
	var randomNum = new Date().getMilliseconds();
	var value = 0;
	var label = label;
	var url = encodeURI(location.href);

	var trackUrl = "http://www.googleadservices.com/pagead/conversion/"+goalId+"/?random="+randomNum+"&value="+value+"&label="+label+"&guid=ON&script=0&url="+url;

	var img = new Image(1, 1); 
	img.src = trackUrl;
	document.body.appendChild(img);
}

function track(platform, elm, cat, label, action, adw_id, adw_label) {

	if(platform === 'ga'){
		ga_track(cat, action, label, elm);

	} else if (platform === 'adw') {
		adwords(adw_id, adw_label);
	} else {
		ga(cat, action, label, elm);
		adwords(adw_id, adw_label);
	}

}
